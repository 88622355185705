import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
	faInstagram, faXTwitter, faFacebook
} from "@fortawesome/free-brands-svg-icons";
import { dateTimeString, eventLocation, eventName } from "common/constants";
import STCLogo from "../images/stcLogo.webp";
import WCALogo from "../images/WCALogo.webp";
import MothershipLogo from "../images/mothership-logo.svg";
import SCCLogo from "../images/scc.png";
import AllTheKitLogo from "../images/allthekit.png";
import RedsLogo from "../images/reds.jpg";
import WWCELogo from "../images/wwce.png";
import { Copyright, Disclaimer } from "./legalNotices";

const FooterDiv = styled.div`
	background-color: rgb(230,230,230);

	.footerContent {
		padding: calc(var(--padding) * 2) var(--padding);
		overflow: hidden;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		max-width: 1200px;
		margin: 0 auto;
		font-size: 0.75rem;

		h2, h3 {
			margin-bottom: var(--small-padding);
		}
	}

	.sponsors {
		display: flex;
		gap: var(--padding);

		.sponsor {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;

			a {
				display: flex;
				align-items: center;
				justify-items: center;
				flex-direction: column;
				color: var(--accent);
			}

			img {
				height: 50px;
				@media (max-width: 800px) {
					height: 30px;
				}
				margin-bottom: var(--small-padding);
			}
		}
	}
`;

const Footer = (): JSX.Element => {
	const [legalNoticeOpen, setLegalNoticeOpen] = useState<string>();

	const openNotice = (id: string) => () => {
		document.body.style.overflow = "hidden";
		setLegalNoticeOpen(id);
	};

	const closeNotice = () => {
		document.body.style.overflow = "inherit";
		setLegalNoticeOpen(undefined);
	};

	return (
		<>
			{ legalNoticeOpen === "disclaimer" ? <Disclaimer closeModal={closeNotice} /> : null }
			{ legalNoticeOpen === "copyright" ? <Copyright closeModal={closeNotice} /> : null }
			<FooterDiv>
				<div className="footerContent">
					<h4>
						{eventName}
					</h4>
					<h5>
						{dateTimeString}
						<br />
						{eventLocation}
					</h5>
					<a href="mailto:info@transitionsalisbury.org">info@transitionsalisbury.org</a>
					<div className="seperator" />
					<div className="columns">
						<div className="column">
							<p>Organised by</p>
							<div className="sponsors">
								<div className="sponsor">
									<a href="https://transitionsalisbury.org" target="_blank" rel="noreferrer">
										<img src={STCLogo} alt="Salisbury Transition City" />
										Salisbury Transition City
									</a>
								</div>
								<div className="sponsor">
									<a href="https://www.wiltshireclimatealliance.org.uk/" target="_blank" rel="noreferrer">
										<img src={WCALogo} alt="Wiltshire Climate Alliance" />
										Wiltshire Climate Alliance
									</a>
								</div>
							</div>
						</div>
						<div className="column">
							<p>Our sponsors</p>
							<div className="sponsors">
								<div className="sponsor">
									<a href="https://www.allthekit.com/" target="_blank" rel="noreferrer">
										<img src={AllTheKitLogo} alt="All The Kit" />
										All The Kit
									</a>
								</div>
								<div className="sponsor">
									<a href="https://wearemothership.com" target="_blank" rel="noreferrer">
										<img src={MothershipLogo} alt="Mothership Software Ltd." />
										Mothership Software
									</a>
								</div>
								<div className="sponsor">
									<a href="https://salisburycitycouncil.gov.uk/" target="_blank" rel="noreferrer">
										<img src={SCCLogo} alt="Salisbury City Council" />
										Salisbury City Council
									</a>
								</div>
								<div className="sponsor">
									<a href="https://www.salisburyreds.co.uk/" target="_blank" rel="noreferrer">
										<img src={RedsLogo} alt="Salisbury Reds" />
										Salisbury Reds
									</a>
								</div>
								<div className="sponsor">
									<a href="https://wwce.org/" target="_blank" rel="noreferrer">
										<img src={WWCELogo} alt="Wiltshire Wildlife Community Energy" />
										Wiltshire Wildlife Community Energy
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="seperator" />
					<p>Follow us...</p>
					<div className="socialLinks">
						<a href="https://www.transitionsalisbury.org/" aria-label="Website" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGlobe} /></a>
						<a href="https://www.facebook.com/transitionsalisbury" aria-label="Facebook" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
						<a href="https://www.instagram.com/salisburytransitioncity/" aria-label="Instagram" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
						<a href="https://twitter.com/salisburytransi" aria-label="X" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faXTwitter} /></a>
					</div>
					<div className="seperator" />
					<p>Legal</p>
					<div>
						<button type="button" onClick={openNotice("disclaimer")}>Disclaimers</button>
						<button type="button" onClick={openNotice("copyright")}>Copyright Notices</button>
					</div>
				</div>
			</FooterDiv>
		</>
	);
};

export default Footer;
